// context/DateRangeContext.js
import React, { createContext, useContext, useState } from 'react';

const DateRangeContext = createContext();

export const DateRangeProvider = ({ children }) => {
    const [dateRange, setDateRange] = useState({
        // start date one week ago
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
        // endDate today
        endDate: new Date().toISOString().split('T')[0],
    });

    return (
        <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
            {children}
        </DateRangeContext.Provider>
    );
};

export const useDateRange = () => {
    const context = useContext(DateRangeContext);
    if (!context) {
        throw new Error('useDateRange must be used within a DateRangeProvider');
    }
    return context;
};