import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import sfyApi from '../api/sfyApi';  // Assuming sfyApi is set up for API requests
import { getCookie } from '../utils/cookies'; // Assuming getCookie is a utility for session handling
import './BarTracksByDecade.css'; // Custom styles for the chart
import { formatGenre } from '../utils/stringUtils';
import { useDateRange } from "../components/DateRangeContext";
import { getSfyCache, updateSfyCache } from "../utils/cache";


const BarChart = () => {
    const [trackData, setTrackData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const svgRef = useRef(null);  // Ref for the SVG container
    const { dateRange } = useDateRange();
    const { startDate, endDate } = dateRange;

    useEffect(() => {
        // Fetch data from the backend API
        const fetchData = async () => {
            const cookieString = getCookie('sessionUser');
            const user = JSON.parse(cookieString);

            // const cachedData = getSfyCache("genresByDecade");

            // if (cachedData) {
            //     setTrackData(cachedData);
            //     return;
            // }



            try {
                setLoading(true);
                const data = await sfyApi.getTracksByDecade(user.email, startDate, endDate);

                updateSfyCache("genresByDecade", JSON.stringify(data));
                setTrackData(data);
                setError(null);
            } catch (err) {
                console.error('Error fetching track data:', err);
                setError('Failed to load data.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate]);

    useEffect(() => {
        const divWidth = document.getElementById("chart-container").offsetWidth;
        const divHeight = document.getElementById("chart-container").offsetHeight - 100;
        const isMobile = window.innerWidth < 768; // Assume mobile if width is less than 768px

        if (!loading && trackData.length > 0 && !error) {
            const svg = d3.select(svgRef.current);
            const width = divWidth;
            const height = isMobile ? divHeight - 75 : divHeight;
            const margin = { top: 20, right: 30, bottom: 150, left: 50 }; // Increased top margin

            // Clear the previous chart (in case of re-render)
            svg.selectAll('*').remove();

            // Set dimensions of the chart
            svg
                .attr('width', width)
                .attr('height', height)
                .attr('viewBox', `0 0 ${width} ${height}`)
                .attr('preserveAspectRatio', 'xMidYMid meet');

            const chartWidth = width - margin.left - margin.right;
            const chartHeight = height - margin.top - margin.bottom;

            // Append a group element to the svg
            const g = svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);

            // Create a tooltip div that is hidden by default
            const tooltip = d3.select('#chart-container')
                .append('div')
                .style('position', 'absolute')
                .style('background-color', 'rgba(0, 0, 0, 0.7)')
                .style('color', 'white')
                .style('padding', '8px')
                .style('border-radius', '4px')
                .style('pointer-events', 'none')
                .style('opacity', 0);

            // X-axis scale for decades (outer scale)
            const fx = d3
                .scaleBand()
                .domain([...new Set(trackData.map(d => d.dec))]) // Unique decades
                .range([0, chartWidth])
                .paddingInner(0.2); // Adjust padding between decade groups

            // Y-axis scale for counts
            const y = d3
                .scaleLinear()
                .domain([0, d3.max(trackData, d => +d.c)]).nice() // Max count, rounded up
                .range([chartHeight, 0]);

            // Color scale for genres
            const genres = [...new Set(trackData.map(d => d.genre_name))];
            const color = d3.scaleOrdinal()
                .domain(d3.range(genres.length))
                .range(d3.quantize(d3.interpolateRainbow, genres.length));

            const barMouseover = function (event, d) {
                tooltip
                    .style('opacity', 1)
                    .html(`<strong>Genre:</strong> ${formatGenre(d.genre_name)}<br><strong>Decade:</strong> ${d.dec}<br><strong>Spins:</strong> ${d.c}`)
                    .style('left', (event.pageX + 10) + 'px')
                    .style('top', (event.pageY - 30) + 'px');
                d3.selectAll(".bars")
                    .style('opacity', 0.2);
                d3.select(event.target).style("opacity", 1);
            };

            const barMouseOut = function (event, d) {
                tooltip
                    .style('opacity', 0);
                d3.selectAll(".bars")
                    .style('opacity', 1);
            };

            // X-axis
            g.append('g')
                .attr('transform', `translate(0, ${chartHeight})`)
                .call(d3.axisBottom(fx))
                .selectAll('text')
                .attr('transform', isMobile ? 'translate(-20, 5) rotate(-90)' : 'translate(0, 0)') // Combined translation and rotation

                .style('text-anchor', isMobile ? 'end' : 'middle')
                .attr('font-size', '14px')
                .attr('fill', 'white');

            // Y-axis
            g.append('g')
                .call(d3.axisLeft(y))
                .selectAll('.domain')  // Target the Y-axis line
                .style('stroke-width', '1px')  // Set the stroke width to a thinner line
                .style('stroke', 'white');  // Set the color of the axis line

            g.selectAll('.tick line')
                .style('stroke-width', '1px')
                .style('stroke', 'white');

            g.selectAll('.tick text')
                .style('fill', 'white')
                .style('font-size', '20px');

            if (!isMobile) {

                // Add a legend below the chart
                const legend = svg.append("g")
                    .attr("class", "legend")
                    .attr("transform", `translate(${margin.left + 10}, ${margin.top})`)  // Adjust to top-right corner
                    .style('opacity', 0.8);  // Make the legend semi-opaque

                // Define the max items per column
                const maxItemsPerColumn = 36;

                // Add legend items for each genre
                genres.forEach((genre, index) => {
                    // Calculate the column and row positions
                    const column = Math.floor(index / maxItemsPerColumn);
                    const row = index % maxItemsPerColumn;


                    const legendRow = legend.append("g")
                        .attr("class", "legend")
                        .attr("transform", `translate(${column * -175}, ${row * 25})`);  // Adjust spacing between columns and rows

                    legendRow.append("rect")
                        .attr("class", "legend")
                        .attr("width", 18)
                        .attr("height", 18)
                        .attr("fill", color(genre))
                        .style('opacity', 0.7);

                    legendRow.append("text")
                        .attr("x", 25)
                        .attr("y", 13)
                        .text(formatGenre(genre))
                        .style("fill", "white")
                        .style("font-size", "12px")
                        .style('opacity', 0.7)
                        .attr("text-anchor", "start");
                });
            }

            // Bars and Labels (grouped by decade and genre)
            g.append("g")
                .selectAll("g")
                .data(d3.group(trackData, d => d.dec)) // Group by decade
                .join("g")
                .attr("transform", ([dec]) => `translate(${fx(dec)}, 0)`)
                .each(function ([dec, data]) {
                    // Calculate the number of genres for this decade
                    const innerScale = d3
                        .scaleBand()
                        .domain(data.map(d => d.genre_name)) // Genres present in this decade
                        .range([0, fx.bandwidth()])
                        .padding(0.1); // Adjust padding between genres in the group

                    // Draw bars for this decade
                    d3.select(this)
                        .selectAll("rect")
                        .data(data)
                        .join("rect")
                        .attr("x", d => innerScale(d.genre_name))
                        .attr("width", innerScale.bandwidth())
                        .attr("y", d => y(+d.c))
                        .attr("height", d => chartHeight - y(+d.c))
                        .attr('class', 'bars')
                        .attr("fill", d => color(d.genre_name))
                        .on("mouseover", barMouseover)
                        .on("mousemove", (event) => {
                            tooltip
                                .style('left', (event.pageX + 10) + 'px')
                                .style('top', (event.pageY - 30) + 'px');
                        })
                        .on("mouseout", barMouseOut);

                    // Draw labels for each bar
                    // d3.select(this)
                    //     .selectAll(".label")
                    //     .data(data)
                    //     .join("text")
                    //     .attr('class', 'label')
                    //     // .attr('transform', 'rotate(-90)')
                    //     .attr('x', d => innerScale(d.genre_name) + innerScale.bandwidth() / 2)
                    //     .attr('y', d => y(+d.c) - 5)
                    //     .text(d => d.c)
                    //     .attr('text-anchor', 'middle')
                    //     .style('font-size', '20px')
                    //     .style('fill', 'white');
                });


        }
    }, [loading, trackData, error]);

    return (
        <div id="chart-container" className="bar-chart-container">
            <h2>Top Genres By Decade</h2>
            <p>This chart shows you how many spins each genre has, grouped by the decade that the song was released.
                It helps visualize which eras are most prominent in your music taste, as well as which genres are most prominent in each era.
                For example, if the tooltip says "Genre: Classic Rock, Decade: 1970s, and Spins: 140, That means you listened to 140 Classic Rock tracks that were released in the 1970s.</p>
            {error && <p>{error}</p>}
            {loading ? <p>Loading...</p> : <svg ref={svgRef}></svg>}
        </div>
    );
};

export default BarChart;
