import React, { useEffect, useState, useRef } from "react";
import sfyApi from '../api/sfyApi';
import './GetMyTopTracks.css';
import { getCookie } from "../utils/cookies";
import { useDateRange } from "../components/DateRangeContext";
import { getSfyCache, updateSfyCache } from "../utils/cache";

// SPOTIFY get playlists
export const GetMyTopTracks = () => {
    const [sfyData, updateSfyData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const hasFetched = useRef(false); // UseRef to track if the request has already been made
    const { dateRange } = useDateRange();
    const { startDate, endDate } = dateRange;

    useEffect(() => {
        const getPlaylists = async () => {
            const cookieString = getCookie("sessionUser");
            const user = JSON.parse(cookieString);

            const cachedData = getSfyCache("listTopTracks");

            if (cachedData) {
                updateSfyData(JSON.parse(cachedData));
                return;
            }

            try {
                setLoading(true);
                const data = await sfyApi.myTopTracks(user.email, startDate, endDate); // Fetch playlists from the backend
                updateSfyCache("listTopTracks", JSON.stringify(data));
                updateSfyData(data);
                setError(null);
            } catch (err) {
                console.error("Error fetching playlists:", err);
                setError("Server Error.");
            } finally {
                setLoading(false); // Set loading to false once request is complete
            }
        };

        if (!hasFetched.current) {
            getPlaylists();
            hasFetched.current = true; // Mark as fetched to prevent duplicate calls
        }
    }, [startDate, endDate]);

    const buildPlaylistContainer = () => {

        if (error) {
            return <p>{error}</p>;
        }

        if ((!sfyData || sfyData.length === 0) && !loading) {
            return <p>No Artists found</p>;
        }

        return (
            <div className="tracks-container">

                <h2>My Top Tracks:</h2>
                <ol>
                    {sfyData.map((item, index) => (
                        <li key={index} className="list-item">
                            {/* item.sfy_url */}
                            <a href="https://www.spotify.com" target="_blank" rel="noopener noreferrer" className="track-link">
                                <img
                                    src={item.image_url}
                                    alt={item.track_name}
                                    className="track-image"
                                />


                                <div className="track-text">
                                    <p className="track-name">{item.track_name}</p>
                                    <p className="track-artist">{item.artist_name}</p>
                                    <div className="track-spins">
                                        <p className="spins">Spins: {item.c}</p>
                                        <p className="change" style={{ color: item.change > 0 ? "green" : "red" }}>
                                            {item.change > 0 ? "+" : ""}{item.change}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </li>
                    ))}
                </ol>
            </div>
        );
    };

    return buildPlaylistContainer();
};

export default GetMyTopTracks;