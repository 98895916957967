import React, { useEffect, useState, useRef } from "react";
import sfyApi from '../api/sfyApi';
import { getCookie } from "../utils/cookies";
import './GetTotalSpinTime.css';
import { useDateRange } from "../components/DateRangeContext";



//LFM get now listening
export const GetTotalSpinTime = () => {
    const [sfyData, updateSfyData] = useState([]);
    const [error, setError] = useState(null); // Error state
    const hasFetched = useRef(false); // UseRef to track if the request has already been made
    const msToHourConversion = 3600000;
    const { dateRange } = useDateRange();
    const { startDate, endDate } = dateRange;

    useEffect(() => {
        const getTotalSpinTime = async () => {
            const cookieString = getCookie("sessionUser");
            const user = JSON.parse(cookieString);
            try {
                const data = await sfyApi.getTotalSpinTime(user.email, startDate, endDate);
                updateSfyData(data);
                setError(null);
            } catch (err) {
                console.error("Error fetching now playing:", err);
                setError("Whoops! Something went wrong with Spotify API.");
            }
        };

        if (startDate && endDate) {
            getTotalSpinTime();
        }
    }, [startDate, endDate]); // 👈 runs again when dates change

    const buildSpinTime = () => {

        if (error) {
            return <p>{error}</p>;
        }

        if (!sfyData) {
            return <p>No Spins found</p>;
        }

        const spinHours = Math.round((sfyData.spin_time / msToHourConversion) * 100) / 100;
        const changeHours = Math.round((sfyData.change / msToHourConversion) * 100) / 100;

        return (
            <div className="spin-time-container" >

                <h2>Total Spin Time:</h2>
                <div className='spin-time-center'>
                    <h1 className="spin-h1">{spinHours} hours</h1>
                    <p
                        className="spin-time-change"
                        style={{ color: changeHours > 0 ? "green" : "red" }}
                    >
                        {changeHours > 0 ? "+" : ""}
                        {changeHours}
                    </p>
                </div>


            </div >




        );
    };

    return buildSpinTime();
};

export default GetTotalSpinTime;