import React, { useEffect, useState, useRef } from "react";
import sfyApi from '../api/sfyApi';
import { getCookie } from "../utils/cookies";
import './GetTotalSpins.css';
import { useDateRange } from "../components/DateRangeContext";



//LFM get now listening
export const GetTotalSpins = () => {
    const [sfyData, updateSfyData] = useState([]);
    const [error, setError] = useState(null); // Error state
    const hasFetched = useRef(false); // UseRef to track if the request has already been made
    const { dateRange } = useDateRange();
    const { startDate, endDate } = dateRange;

    useEffect(() => {
        const getTotalSpins = async () => {
            const cookieString = getCookie("sessionUser");
            const user = JSON.parse(cookieString);
            try {
                const data = await sfyApi.getTotalSpins(user.email, startDate, endDate);
                updateSfyData(data);
                setError(null);
            } catch (err) {
                console.error("Error fetching now playing:", err);
                setError("Whoops! Something went wrong with Spotify API.");
            }
        };

        if (startDate && endDate) {
            getTotalSpins();
        }
    }, [startDate, endDate]);

    const buildSpins = () => {

        if (error) {
            return <p>{error}</p>;
        }

        if (!sfyData) {
            return <p>No Spins found</p>;
        }

        const spinsFormatted = sfyData.spins?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format the number with commas

        return (
            <div className="spins-container" >

                <h2>Total Spins:</h2>
                <div className='spins-count'>
                    <h1 className="spin-h1">{spinsFormatted} spins</h1>
                    <p
                        className="spins-count-change"
                        style={{ color: sfyData.change > 0 ? "green" : "red" }}
                    >
                        {sfyData.change > 0 ? "+" : ""}
                        {sfyData.change}
                    </p>
                </div>


            </div >
        );
    };

    return buildSpins();
};

export default GetTotalSpins;