// components/GlobalDateSelector.js

import React from 'react';
import { useDateRange } from './DateRangeContext';
import './GlobalDateSelector.css';
const GlobalDateSelector = () => {
    const { dateRange, setDateRange } = useDateRange();

    const handleStartDateChange = (e) => {
        setDateRange({ ...dateRange, startDate: e.target.value });
        localStorage.removeItem("sfyCache"); // invlidate cache 
    };

    const handleEndDateChange = (e) => {
        setDateRange({ ...dateRange, endDate: e.target.value });
        localStorage.removeItem("sfyCache");  // invlidate cache 
    };

    return (
        <div className="date-selector-container">
            <label className="date-label">
                Show Me Data From:
                <input
                    type="date"
                    value={dateRange.startDate}
                    onChange={handleStartDateChange}
                    className="date-input"
                />
            </label>
            <label className="date-label">
                To:
                <input
                    type="date"
                    value={dateRange.endDate}
                    onChange={handleEndDateChange}
                    className="date-input"
                />
            </label>
        </div>
    );
};

export default GlobalDateSelector;