import React, { useEffect, useState, useRef } from "react";
import sfyApi from '../api/sfyApi';
import './GetDatePicker.css';
import GlobalDateSelector from "./GlobalDateSelector";
import { getCookie } from "../utils/cookies";


//LFM get now listening
export const GetDatePicker = () => {
    const [sfyData, updateSfyData] = useState([]);
    const [error, setError] = useState(null); // Error state
    const hasFetched = useRef(false); // UseRef to track if the request has already been made

    // useEffect(() => {


    //     const getUserProfile = async () => {
    //         try {
    //             const data = await sfyApi.getCurrentUserProfile(); // Fetch now playing track from the backend
    //             updateSfyData(data);
    //             setError(null); // Reset error state on successful fetch
    //         } catch (err) {
    //             console.error("Error fetching user profile:", err);
    //             setError("Whoops! Something went wrong with Spotify API.");
    //         }


    //     };
    //     if (!hasFetched.current) {
    //         getUserProfile();
    //         hasFetched.current = true; // Mark as fetched to prevent duplicate calls
    //     }
    // }, []); // Empty dependency array to run only on mount/unmount

    const buildDatePicker = () => {

        // const cookieString = getCookie("sessionUser");
        // const user = JSON.parse(cookieString);

        if (error) {
            return <p>{error}</p>;
        }


        if (!sfyData) {
            return (
                <div>
                    <p>No user profile Info</p>
                </div>
            );
        }


        const displayName = sfyData.display_name;
        const profileImageUrl = null;//sfyData.images;

        const followers = null;//sfyData.followers;



        return (
            <div className="profile-container" >
                <img
                    src={profileImageUrl}
                    className="profile-image"
                />
                {/* <p className="name">{user.firstName + " " + user.lastName}</p> */}
                {/* <p className="followers"> Followers: {followers}</p> */}
                {/* <p className="sfy-username">Spotify Username: {displayName}</p> */}

                <GlobalDateSelector />



            </div>
        );
    };

    return buildDatePicker();
};

export default GetDatePicker;